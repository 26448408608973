import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"

import Termly from "../../components/termly"

import Hero from "../../components/hero"
import Contact from "../../components/contact"
import Layout from "../../components/layout"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Disclaimer" />
    <Hero
      headline={(<span>Website disclaimer.</span>)}
      dek="You can expect truth and facts from us, and we'll operate in good faith."
      readMore="Read the policy"
      readMoreLink="#termly"
    />
    <Termly doc="2f2c8eee-d58a-4e91-b6a1-ff94753f3157"/>
  </Layout>
)

export default PrivacyPage
